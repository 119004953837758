<template>
  <div>
    <div class="d-flex justify-content ml-2 align-items-center my-2">
      <button
        class="btn btn-sm rounded-circle p-25 cursor-pointer"
        :class="isDark ? 'btn-danger' : 'btn-primary'"
        @click="goBack()"
      >
        <i class="feather icon-arrow-left arrow-icon"></i>
      </button>
      <div class="ml-1 text-capitalize text-dark cursor-pointer goback-text"
        @click="goBack()"
      >
        Regresar
      </div>
    </div>
    <!-- Table Container Card -->
    <b-card class="m-2" no-body>
      <b-card-header class="pb-50">
        <h5 class="text-capitalize">
          <span v-if="!isUpdating">{{ $t("layout_builder.action_add") }}</span>
          <span v-else>{{ $t("layout_builder.action_update") }}</span>
        </h5>
      </b-card-header>
      <b-card-body>
        <validation-observer ref="refFormObserver">
          <div class="m-2">
            <b-row>
              <!-- Per Page -->
              <b-col cols="12" md="8">
                <b-row>
                  <b-col class="mb-1 mb-md-0">
                    <validation-provider
                      #default="validationContext"
                      name="Layout name"
                      rules="required"
                    >
                      <label class="d-block">
                        {{ $t("layout_builder.fields.name.label") }}
                      </label>
                      <b-form-input
                        v-model="layoutName"
                        class="d-inline-block mr-1"
                        :placeholder="$t('layout_builder.fields.name.label')"
                      />
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-col>

                  <b-col class="mb-1 mb-md-0">
                    <label class="d-block">
                      {{ $t("layout_builder.fields.tenant.label") }}
                    </label>
                    <v-select
                      v-model="selectedTenant"
                      :options="tenantOptions"
                      class="w-100"
                      :reduce="(val) => val.value"
                    />
                  </b-col>

                  <b-col class="mb-1 mb-md-0">
                    <validation-provider
                      #default="validationContext"
                      name="Users"
                      :rules="!user.is_master_admin ? 'required' : null"
                    >
                      <label class="d-block">
                        {{ $t("layout_builder.fields.users.label") }}
                        {{
                          user.is_master_admin
                            ? `(${$t("layout_builder.strings.optional")})`
                            : ""
                        }}
                      </label>
                      <v-select
                        v-model="selectedUsers"
                        :options="usersOptions"
                        class="w-100"
                        :reduce="(val) => val.value"
                        multiple
                      />
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-col>

                  <b-col cols="2" class="mb-1 mb-md-0" v-if="user.is_master_admin">
                    <label class="d-block">
                      {{ $t("layout_builder.fields.default.label") }}
                    </label>
                    <b-form-checkbox
                      id="is_default"
                      name="is_default"
                      v-model="isDefault"
                      class="text-capitalize py-50"
                      switch
                    />
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                cols="12"
                md="4"
                class="d-flex flex-column align-items-end justify-content-end mb-1 mb-md-0"
              >
                <div>
                  <!-- Add new item -->
                  <b-button
                    class="mr-1"
                    variant="primary"
                    :disabled="totalSize >= maxRows * 12"
                    @click="addItem()"
                  >
                    <span class="text-nowrap text-capitalize">
                      {{ $t("layout_builder.btns.add_slot") }}
                    </span>
                  </b-button>
                  <!-- Save changes -->
                  <b-button
                    variant="success"
                    :disabled="items.length === 0 || totalSize < maxRows * 12"
                    @click="save()"
                  >
                    <span class="text-nowrap text-capitalize">
                      {{ $t("layout_builder.btns.save") }}
                    </span>
                  </b-button>
                </div>
                <div>
                  <p
                    v-if="items.length && totalSize < maxRows * 12"
                    class="text-danger mt-1 mb-0"
                  >
                    <i class="feather icon-alert-triangle mr-25"></i>
                    {{ $t('layout_builder.messages.no_blank_spaces') }}
                  </p>
                </div>
              </b-col>
            </b-row>
          </div>
        </validation-observer>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              md="12"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <div class="w-100">
                <div class="grid-stack">
                  <widget
                    v-for="widget in items"
                    :widget="widget"
                    :key="widget.id"
                    @remove-item="onRemoveItem"
                  />
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BFormCheckbox,
  BFormInvalidFeedback,
} from "bootstrap-vue";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import Widget from "./Widget.vue";
import useLayoutConfig from "./useLayoutConfig";
import store from "@/store";
import { computed } from "@vue/composition-api";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BFormCheckbox,
    BFormInvalidFeedback,
    vSelect,
    Widget,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  setup(_, context) {
    return {
      goBack: () => {
        // Get the base part of the current route
        const basePath = context.root.$route.path.split('/')[1]; // Assuming the base is the first segment of the route
        // Navigate back to the base route
        context.root.$router.push(`/${basePath}`);
      },
      isDark: computed(() => store.state.appConfig.layout.skin === "dark"),

      ...useLayoutConfig()
    };
  },
};
</script>



<style lang="scss" scoped>
@import "~gridstack/dist/gridstack.min.css";

.grid-stack {
  width: 100%;
  border: 2px dashed rgb(190, 190, 190);

  .gs-item {
    $gridstack-columns: 12;
    @for $i from 1 through $gridstack-columns {
      &[gs-w="#{$i}"] {
        width: (100% / $gridstack-columns) * $i !important;
      }
      &[gs-x="#{$i}"] {
        left: (100% / $gridstack-columns) * $i !important;
      }
      &.grid-stack-item[gs-min-w="#{$i}"] {
        min-width: (100% / $gridstack-columns) * $i !important;
      }
      &.grid-stack-item[gs-max-w="#{$i}"] {
        max-width: (100% / $gridstack-columns) * $i !important;
      }
    }
  }
}
.arrow-icon {
  font-size: 12px;
}
.goback-text {
  position: relative;
  top: .1rem;
  margin-left: .5rem !important;
}
</style>
