import { useToast } from "vue-toastification/composition";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import router from "@/router";
import axios from "@axios";
import {
  computed,
  reactive,
  toRefs,
  onMounted,
  nextTick,
  ref,
  watch,
} from "@vue/composition-api";

import { useUtils as useI18nUtils } from "@core/libs/i18n";
import "gridstack/dist/gridstack.min.css";
import "gridstack/dist/dd-resizable";
import "gridstack/dist/dd-element";
import { GridStack } from "gridstack";
import { v4 as uuidv4 } from "uuid";

export default function useLayoutConfig() {

  const state = reactive({
    items: [],
    grid: null,
    draggable: true,
    resizable: true,
    maxRows: 8,
    layoutId: null,
    isUpdating: false,
  });

  const layoutName = ref("");
  const tenants = ref([]);
  const tenantOptions = ref([]);
  const selectedTenant = ref(null);

  const users = ref([]);
  const selectedUsers = ref([]);
  const usersOptions = ref([]);

  const isDefault = ref(false);

  const user = store.getters["user/getUser"];
  const toast = useToast();
  const { refFormObserver, getValidationState } = formValidation();
  const { t } = useI18nUtils();
  const trans = {
    'layout_builder.messages.no_enough_space': t('layout_builder.messages.no_enough_space'),
    'layout_builder.messages.created_success': t('layout_builder.messages.created_success'),
    'layout_builder.messages.updated_success': t('layout_builder.messages.updated_success'),
  };

  // Computed properties ------------------------------------
  const totalSize = computed(() => {
    let total = 0;
    state.items.forEach((item) => (total += item.layout.w * item.layout.h));
    return total;
  });

  // Watchers ------------------------------------
  watch(selectedTenant, (idTenant) => {
    if (idTenant) {
      selectedUsers.value = [];
      loadTenantsById(idTenant);
    } else {
      usersOptions.value = [];
    }
  });


  // Methods ------------------------------------
  const loadGrid = (data) => {
    const grid = data.data;

    if (grid) {
      grid.forEach((item) => {
        const widget = {
          id: item.id,
          text: `${item.w} x ${item.h}`,
          layout: {
            x: item.x,
            y: item.y,
            w: item.w,
            h: item.h,
            minW: item.minW,
            minH: item.minH,
          },
        };
        state.items.push(widget);
        nextTick(() => state.grid.makeWidget(`#${widget.id}`));
      });
    }
  };

  const loadInitData = async () => {
    const tenants = await axios.get("v1/tenant");
    tenantOptions.value = tenants.data.data.map((tenant) => ({
      value: tenant.id,
      label: tenant.name,
    }));
  };

  const loadTenantsById = (idTenant) => {
    return axios
      .get(`v1/tenants/get-tenant-users/${idTenant}`)
      .then((resp) => {
        usersOptions.value = [];
        usersOptions.value = resp.data.data.map((user) => ({
          value: user.id,
          label: user.name,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addItem = () => {
    const widget = {
      id: uuidv4(),
      text: "3 x 2",
      layout: {
        x: null,
        y: null,
        w: 3,
        h: 2,
        minW: 2,
        minH: 1,
      },
    };

    const willItFit = state.grid.willItFit({
        x: widget.layout.x,
        y: widget.layout.y,
        w: widget.layout.w,
        h: widget.layout.h
      });

    if (willItFit) {
      state.items.push(widget);
      nextTick(() => state.grid.makeWidget(`#${widget.id}`));
    } else {
      toast({
        component: ToastificationContent,
        props: {
          title: trans['layout_builder.messages.no_enough_space'],
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    }
  };

  const updateItem = (_item) => {
    const item = state.items.find((item) => item.id === _item.id);
    const itemIndex = state.items.indexOf(item);

    item.layout.x = _item.x;
    item.layout.y = _item.y;
    item.layout.w = _item.w;
    item.layout.h = _item.h;
    item.layout.minW = _item.minW;
    item.layout.minH = _item.minH;
    item.text = `${_item.w} x ${_item.h}`;

    state.items[itemIndex] = item;
  };

  const removeItem = (node, index) => {
    nextTick(() => state.grid.removeWidget(node));
    state.items.splice(index, 1);
  };

  const onRemoveItem = (itemId) => {
    const itemIndex = state.items.findIndex((item) => item.id === itemId);
    const node = document.getElementById(itemId);
    removeItem(node, itemIndex);
  };

  const save = async () => {
    const validation = await refFormObserver.value.validate();

    if (validation) {
      const grid = state.grid.save().map((item, index) => {
        const node = {
          ...item,
          name: null,
          type: null,
          pos: index + 1,
          data: null,
        };

        return node;
      });
      storeGrid(
        layoutName.value,
        grid,
        selectedUsers.value,
        selectedTenant.value,
        isDefault.value
      );
    }
  };

  const storeGrid = (name, grid, users, id_tenant, is_default) => {
    const data = {
      name,
      data: grid,
      users,
      id_tenant,
      is_default,
    };

    if (!state.isUpdating) {
      axios.post("v1/layout", { ...data }).then(({ data }) => {
        toast({
          component: ToastificationContent,
          props: {
            title: trans['layout_builder.messages.created_success'],
            icon: "CheckIcon",
            variant: "success",
          },
        });

        state.isUpdating = true;
        state.layoutId = data.data.id;

        router.push({ path: `/layouts/config-layout/${state.layoutId}` });
      });
    } else {
      axios.put(`v1/layout/${state.layoutId}`, { ...data }).then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: trans['layout_builder.messages.updated_success'],
            icon: "CheckIcon",
            variant: "success",
          },
        });
      });
    }
  };

  // Hooks ------------------------------------
  onMounted(async () => {

    state.grid = GridStack.init({
      acceptWidgets: true,
      float: true,
      margin: 5,
      row: state.maxRows,
      cellHeight: "90px",
    });

    state.grid.on("resizestop", (_, el) => {
      let node = el.gridstackNode;
      updateItem(node);
    });

    state.grid.on("dragstop", (_, el) => {
      let node = el.gridstackNode;
      updateItem(node);
    });

    loadInitData();

    // Load data if current action is updating
    if (router.currentRoute.params.id_layout) {
      state.isUpdating = true;
      state.layoutId = router.currentRoute.params.id_layout;

      const { data } = await axios.get(`v1/layout/${state.layoutId}`, { crud: true });
      const response = data.data;

      layoutName.value = response.name;
      selectedTenant.value = response.id_tenant;
      isDefault.value = response.is_default === 1;

      await loadTenantsById(response.id_tenant);
      selectedUsers.value = response.users.map(item => item.id);

      loadGrid(response);
    }
  });

  return {
    addItem,
    removeItem,
    totalSize,
    onRemoveItem,
    save,
    ...toRefs(state),
    user,
    layoutName,
    tenants,
    tenantOptions,
    selectedTenant,
    selectedUsers,
    usersOptions,
    isDefault,
    users,
    refFormObserver,
    getValidationState,
    required,
  };
}
