<template>
  <div class="grid-stack-item" v-bind="gridStackAttributes">
    <button
      class="btn btn-sm btn-danger rounded-circle p-50 btn-remove"
      @click="$emit('remove-item', widget.id)"
    >
      <i class="feather icon-x"></i>
    </button>
    <div class="grid-stack-item-content">
      {{ widget.text }}
    </div>
  </div>
</template>
<script>
import get from "lodash/get";
export default {
  props: {
    widget: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    gridStackAttributes() {
      return {
        id: get(this.widget, "id", "widget"),
        "gs-id": get(this.widget, "id", "widget"),
        "gs-x": get(this.widget, "layout.x", 0),
        "gs-y": get(this.widget, "layout.y", 0),
        "gs-w": get(this.widget, "layout.w", 2),
        "gs-h": get(this.widget, "layout.h", 1),
        "gs-min-w": get(this.widget, "layout.minW", 4),
        "gs-min-h": get(this.widget, "layout.minH", 4),
      };
    },
  },
};
</script>
<style lang="scss">
.grid-stack-item-content {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3182ce;
  background-color: #bee3f8;
  font-weight: 600;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}
.btn-remove {
  position: relative;
  z-index: 1;
  float: right;
}
</style>
